import React from 'react';
import Input from './Input';
import Checkbox from './Checkbox';
import Dropdown from './Dropdown';
import { formItemTypes } from '../../../constants';
import FormItemsBuilderProps from '../../../props/FormItemsBuilderProps';

const typeToComponentMap = (item) => {
  if (!item.visible) {
    return null;
  }

  if (item.itemType === formItemTypes.INPUT) {
    return <Input key={item.name} {...item} />;
  }

  if (item.itemType === formItemTypes.CHECKBOX) {
    return <Checkbox key={item.name} {...item} />;
  }

  if (item.itemType === formItemTypes.DROPDOWN) {
    return <Dropdown key={item.name} {...item} />;
  }

  return null;
};

const FormItemsBuilder = ({ data }) => {
  return <>{data.map(typeToComponentMap)}</>;
};

FormItemsBuilder.propTypes = FormItemsBuilderProps;

export default FormItemsBuilder;
