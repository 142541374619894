const useGetProductFromUrl = () => {
  if (typeof window !== "undefined") {
    const { pathname } = window.location;
    const urlItems = pathname.split("/").filter((i) => i.length > 0);

    if (urlItems.length > 1 && urlItems[0] === "products") {
      return urlItems[1];
    }
  }

  return null;
};

export default useGetProductFromUrl;
