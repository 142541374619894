import React from 'react';
import { colors, viewports } from '../../style-vars';

const VietFormStyles = () => (
  <style jsx global>{`
    .top-v-form {
      padding: 32px;
    }
    .v-form-h2 {
      font-size: 32px;
      font-weight: 800;
      text-align: center;
    }
    .v-form-p {
      text-align: center;
      color: #748093;
    }
    .v-wrapper {
      padding: 64px 32px;
    }
    .v-form {
      padding: 40px !important;
      background-color: #fff;
      padding-bottom: 20px !important;
      max-width: 650px !important;
      margin: auto !important;
      margin-top: 0 !important;
      box-shadow: 0 4px 30px rgb(0 0 0 / 25%);
      border-radius: 6px;
    }
    @media ${viewports.smOrSmaller} {
      .top-v-form {
        padding: 16px;
      }
      .v-form-h2 {
        text-align: left;
      }
      .v-form-p {
        text-align: left;
      }
      .v-wrapper {
        padding: 0;
        margin-bottom: 32px;
      }
      .v-form {
        padding: 16px !important;
      }
    }
  `}</style>
);

export default VietFormStyles;
