import React, { useRef, useState, useEffect } from "react";
import { Form, Button, Row } from "antd";
import axios from "axios";
import { navigate } from "gatsby";
import {
  hubSpotFormSubmitBaseUrl,
  formItemVisibilityEventName,
  zipValidationRegExp,
} from "../../constants";
import FormItemsBuilder from "./FormItems/FormItemsBuilder";
import { createFormDataToSend } from "../../utils/form-utils";
import { getThankYouUrl } from "../../utils/url-utils";

import chevron from "../../images/svg/chevron_left.svg";
import useGetProductFromUrl from "../../hooks/use-get-product-from-url";
import { LeadFormDefaultProps, LeadFormProps } from "../../props/LeadFormProps";
import VietFormStyles from "./VietFormStyles";
import { submitForm, VIETNAMESE_FORM } from "../../utils/firebase/forms";

const CustomForm = (props) => {
  const {
    formName,
    formId,
    leadType,
    thankYouPath,
    formData,
    onValuesChange,
    toggleVisibilityMap,
    formItemsToSend,
    useFormValueAsDataLayerEventName,
    formValueForDataLayerEventName,
    dataLayerEventNameAppendix,
    initialValues,
    displayForm,
  } = props;
  const form = useRef(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(formData);
  const [zipError, setZipError] = useState("");
  const product = useGetProductFromUrl();

  /*
   * Event Listener that is able to toggle visibility of Form items based on
   * conditions passed with the toggleVisibilityMap prop of the component.
   *
   * E.g. - selecting "Other" in the "Industry" dropdown, toggles the visibility
   * of the "Business Type" form item.
   * */
  const visibilityListener = (e) => {
    const { key, value } = e.detail;
    const toggle = toggleVisibilityMap.find((item) => item.changeOnKey === key);

    if (toggle) {
      const newData = data.map((item) => {
        if (item.name === toggle.togglesItem) {
          return { ...item, visible: toggle.changeOnValue === value };
        }

        return item;
      });
      setData(newData);
    }
  };

  useEffect(() => {
    setData(formData);
  }, [formData]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener(formItemVisibilityEventName, visibilityListener);
    }

    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener(
          formItemVisibilityEventName,
          visibilityListener
        );
      }
    };
  });

  const onFinish = async (values) => {
    setLoading(true);

    // const address = zipCodesMap.get(values.zip);
    if (!zipValidationRegExp.test(values.zip)) {
      setZipError("Please enter a valid zip code");
      return;
    }

    const dataToSend = {
      first_name: values.firstname,
      last_name: values.lastname,
      email: values.email.trim().toLowerCase(),
      phone: values.phone,
      industry_employed: values.industry_employed,
      // city: address.city,
      // state: address.state,
      // zip: address.zip_code,
      zip: values.zip,
    };

    const result = await submitForm(dataToSend, VIETNAMESE_FORM);

    if (result) {
      setLoading(false);
      window.sessionStorage.setItem("Name", values.firstname);
      const thankYouUrl =
        typeof window !== "undefined"
          ? getThankYouUrl(window.location.pathname)
          : null;

      if (typeof window !== `undefined` && typeof dataLayer !== `undefined`) {
        const eventName = useFormValueAsDataLayerEventName
          ? `${values[formValueForDataLayerEventName]}${dataLayerEventNameAppendix}`
          : `${leadType}${dataLayerEventNameAppendix}`;

        // eslint-disable-next-line no-undef
        dataLayer.push({ event: eventName });
      }

      navigate(thankYouUrl || thankYouPath);
    } else {
      setLoading(false);
    }
  };

  if (!displayForm) {
    return null;
  }

  return (
    <section className="lead-form-wrapper" ref={form}>
      <Form
        name={formName}
        layout="vertical"
        className="v-form"
        initialValues={{
          remember: false,
          ...initialValues,
        }}
        onValuesChange={onValuesChange}
        onFinish={onFinish}
        style={{
          maxWidth: "650px",
          padding: "40px ",
          backgroundColor: "#fff",
          margin: "auto",
          boxShadow: "0 4px 30px rgb(0 0 0 / 25%)",
          borderRadius: "6px",
        }}
      >
        {/* Form Items */}
        <Row gutter={[32, 0]}>
          <FormItemsBuilder data={data} />
        </Row>

        <div className="text-[12px] text-danger">{zipError}</div>

        {/* Submit */}
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="cta-primary"
            style={{ marginTop: 20 }}
            loading={loading}
            size="small"
          >
            Submit
            <img
              src={chevron}
              alt="chevron icon"
              style={{ margin: "5px 5px 2px 10px" }}
            />
          </Button>
        </Form.Item>
      </Form>
      <VietFormStyles />
    </section>
  );
};

CustomForm.propTypes = LeadFormProps;
CustomForm.defaultProps = LeadFormDefaultProps;

export default CustomForm;
