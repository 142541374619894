import React from 'react';
import {
  any,
  arrayOf,
  bool,
  number,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import { Form, Select } from 'antd';

const { Option } = Select;

const Dropdown = ({ label, name, rules, placeholder, options }) => {
  return (
    <Form.Item
      style={{ overflow: 'auto' }}
      name={name}
      rules={rules}
      label={label}
    >
      <Select placeholder={placeholder}>
        {options.map(({ title, value }) => (
          <Option key={value} value={value}>
            {title}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};

Dropdown.propTypes = {
  label: string.isRequired,
  name: string.isRequired,
  rules: arrayOf(
    shape({
      required: bool,
      message: string,
      // eslint-disable-next-line react/forbid-prop-types
      pattern: any,
    })
  ),
  placeholder: string.isRequired,
  options: arrayOf(
    shape({
      value: string,
      title: string,
    })
  ).isRequired,
};

Dropdown.defaultProps = {
  rules: [],
};

export default Dropdown;
