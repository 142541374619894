import React from "react";
import { bool, string } from "prop-types";
import FullQuoteBg from "../_NewAnimated/FullQuote/FullQuoteBg";
import {
  emailValidationRegExp,
  formItemTypes,
  phoneValidationRegExp,
  zipValidationRegExp,
} from "../../constants";
import CustomForm from "./Form";
import VietFormStyles from "./VietFormStyles";

const formDataToSend = [
  "firstname",
  "lastname",
  "email",
  "phone",
  "employment",
];

const shortFormDataToSend = [
  "firstname",
  "lastname",
  "email",
  "phone",
  "employment",
];

const formBuild = [
  {
    itemType: formItemTypes.INPUT,
    itemSubType: "text",
    label: "First name",
    name: "firstname",
    rules: [
      {
        required: true,
        message: "Please enter your name",
        min: 3,
      },
    ],
    placeholder: "e.g. John",
    visible: true,
  },
  {
    itemType: formItemTypes.INPUT,
    itemSubType: "text",
    label: "Last name",
    name: "lastname",
    rules: [
      {
        required: true,
        message: "Please enter your lastname",
        min: 3,
      },
    ],
    placeholder: "e.g. Doe",
    visible: true,
  },
  {
    itemType: formItemTypes.INPUT,
    itemSubType: "tel",
    label: "Phone Number",
    name: "phone",
    rules: [
      {
        required: true,
        message: "Please enter your phone",
        pattern: phoneValidationRegExp,
      },
    ],
    placeholder: "e.g. (555) 555-1243 ",
    visible: true,
  },
  {
    itemType: formItemTypes.INPUT,
    itemSubType: "email",
    label: "Email",
    name: "email",
    rules: [
      {
        required: true,
        message: "Please enter a valid email",
        pattern: emailValidationRegExp,
      },
    ],
    placeholder: "e.g. john_doe@mybusiness.com",
    visible: true,
  },
  {
    itemType: formItemTypes.INPUT,
    itemSubType: null,
    label: "Zip Code",
    name: "zip",
    rules: [
      {
        required: true,
        message: "Please enter your zip code",
        min: 4,
        pattern: zipValidationRegExp,
      },
      // () => ({
      //   validator(_, value) {
      //     const address = zipCodesMap.get(value);
      //     if (address && address.zip_code) {
      //       return Promise.resolve();
      //     }
      //     return Promise.reject("Please enter a valid zip code");
      //   },
      // }),
    ],
    placeholder: "e.g. 10001",
    visible: true,
  },
  {
    itemType: formItemTypes.INPUT,
    itemSubType: "text",
    label: "Are you currently employed in this industry?",
    name: "industry_employed",
    rules: [
      {
        required: true,
        message: "Please enter answer",
        min: 3,
      },
    ],
    placeholder: "Please answer",
    visible: true,
  },
  // {
  //   itemType: formItemTypes.CHECKBOX,
  //   itemSubType: null,
  //   label: 'I agree to receive text message updates',
  //   name: 'text_messages',
  //   visible: true,
  // },
];

const VietForm = (props) => {
  const {
    useFormValueAsDataLayerEventName,
    formValueForDataLayerEventName,
    dataLayerEventNameAppendix,
    leadType,
    formHeader,
    formSubHeader,
    useShortForm,
  } = props;
  return (
    <section>
      <div className="top-v-form" style={{ padding: "32px" }}>
        <h2 className="v-form-h2">
          Trở thành Agent cho SpotOn <span className="blue_dot">.</span>
        </h2>
        <p className="v-form-p">
          Chúng tôi đang tìm kiếm những người có thể phát triển mối quan hệ với
          khách hàng và cung cấp dịch vụ trực tiếp mà các doanh nghiệp nói tiếng
          Việt xứng đáng có được.
        </p>
      </div>
      <FullQuoteBg imageName="5.png">
        <div className="v-wrapper">
          <CustomForm
            leadType={leadType}
            header={formHeader}
            subHeader={formSubHeader}
            formId="1e145e91-0d3d-4aba-a8b2-48c7282bdee0"
            formName="Vietnamese Recruitment"
            formData={formBuild}
            formItemsToSend={
              useShortForm ? shortFormDataToSend : formDataToSend
            }
            useFormValueAsDataLayerEventName={useFormValueAsDataLayerEventName}
            formValueForDataLayerEventName={formValueForDataLayerEventName}
            dataLayerEventNameAppendix={dataLayerEventNameAppendix}
            thankYouPath="/lp/thank-you"
          />
        </div>
      </FullQuoteBg>
      <VietFormStyles />
    </section>
  );
};

VietForm.propTypes = {
  leadType: string,
  formHeader: string,
  formSubHeader: string,
  useFormValueAsDataLayerEventName: bool,
  formValueForDataLayerEventName: string,
  dataLayerEventNameAppendix: string,
  useShortForm: false,
};

VietForm.defaultProps = {
  leadType: "",
  formHeader: "",
  formSubHeader: "",
  useFormValueAsDataLayerEventName: false,
  formValueForDataLayerEventName: "",
  dataLayerEventNameAppendix: "Lead-lp",
  useShortForm: false,
};

export default VietForm;
